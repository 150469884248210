import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { PuzzlePieceIcon } from "@heroicons/react/24/outline";
// import VolumeControl from "@app/profile/VolumeControl";
import MemoXBare from "@assets/SVG/XBare";
// import MemoMedium from "@assets/SVG/Medium";
import MemoLinkedInBare from "@assets/SVG/LinkedInBare";
import MemoTelegramBare from "@assets/SVG/TelegramBare";
import MemoDiscord from "@assets/SVG/Discord";
// import MemoTikTok from "@assets/SVG/TikTok";
// import MemoInstagram from "@assets/SVG/Instagram";
import MemoLogout from "@assets/SVG/Logout";
import MemoLogo from "@assets/SVG/Logo/logo";
import { useStytch, useStytchUser } from "@stytch/nextjs";
import Link from "next/link";
import { globalOverlayProxy } from "@/store/globalStore";

export const UserDropdownModal = ({
    show,
    setShow,
}: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) => {
    // const [volume, setVolume] = useState(0);

    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    // const [firstName, setFirstName] = useState("")

    const router = useRouter();
    const pathname = usePathname();
    const { user } = useStytchUser();

    const [items, setItems] = useState([
        {
            icon: PuzzlePieceIcon,
            name: "Browser Extension",
        },
        // {
        //     icon: RocketLaunchIcon,
        //     name: "Download Web App",
        // },
        // {
        //     icon: ChatBubbleLeftIcon,
        //     name: "Contact Us",
        // },
    ]);
    const { session } = useStytch();

    useEffect(() => {
        if (session) {
            setItems([
                ...items,
                {
                    icon: MemoLogout as any,
                    name: "Log Out",
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    const signOut = () => {
        session.revoke();
    };

    const handleRouting = (item: string) => {
        setShow(false);
        if (item === "Log Out") {
            return signOut();
        }

        if (item === "Contact Us") {
            return router.push("/profile/contact-us");
        }
    };

    const socials = [
        {
            href: "https://twitter.com/goblockbeat",
            icon: MemoXBare,
            name: "X",
        },
        // {
        //     href: "https://www.instagram.com/getblockbeat/",
        //     icon: MemoInstagram,
        // },
        // {
        //     href: "https://www.tiktok.com/@getblockbeat",
        //     icon: MemoTikTok,
        // },
        // {
        //     href: "https://getblockbeat.medium.com/",
        //     icon: MemoMedium,
        // },
        {
            href: "https://www.linkedin.com/company/blockbeat/",
            icon: MemoLinkedInBare,
            name: "LinkedIn",
        },
        {
            href: "https://discord.com/invite/VCKB4Ayg8J",
            icon: MemoDiscord,
            name: "Discord",
        },
        {
            href: "https://t.me/+DdibR6Z6Gyo3ZTUx",
            icon: MemoTelegramBare,
            name: "Telegram",
        },
    ];

    return (
        <div
            onPointerEnter={() => {
                if (!show) {
                    globalOverlayProxy.setZIndex = true;
                    setShow(true);
                }
            }}
            onPointerLeave={() => {
                globalOverlayProxy.setZIndex = false;
                setShow(false);
            }}
            className="absolute right-[127px] top-[21px] flex w-[max-content] flex-col items-start overflow-hidden rounded-lg border-1 border-[#2C323D] bg-[#252932] pb-1"
        >
            <Link
                href="/profile/subscription"
                passHref
                className={`w-full overflow-hidden rounded-lg ${
                    !pathname?.includes("/profile") && "cursor-pointer hover:brightness-75"
                }`}
                legacyBehavior
            >
                <a
                    onClick={() => setShow(false)}
                    className="w-full cursor-pointer items-center hover:brightness-75"
                >
                    <div className="flex w-full items-center gap-2 border-b-1 border-[#2F343F] bg-[#2F343F] p-2">
                        <MemoLogo className="h-6 w-6" />
                        <div className="flex flex-col items-start p-0">
                            <div className="flex items-center gap-1 p-0">
                                <p className="w-40 truncate text-xs font-normal">{user?.emails[0]?.email}</p>
                                <div className="flex h-3 items-center justify-center rounded-2xl bg-[#C83D4D] px-1 py-0">
                                    <p className="text-[10px] font-light uppercase leading-3 text-white">
                                        BETA
                                    </p>
                                </div>
                            </div>
                            <p className="text-xs font-light text-[#7A869B]">@BlockBeat</p>
                        </div>
                    </div>
                </a>
            </Link>
            <div className="flex w-full flex-col items-start px-0 py-1">
                {/* <VolumeControl volume={volume} setVolume={setVolume} /> */}

                {/* <div className="my-1 flex w-full border-t-1 border-[#2F343F]" /> */}

                {items.slice(0, 2).map((item, i) => (
                    <a
                        href={
                            item.name === "Browser Extension"
                                ? "https://chrome.google.com/webstore/detail/blockbeat-crypto-hover/cgkdbhlfmjiglkhcdjjmfbajmddenihi"
                                : (null as any)
                        }
                        target={item.name === "Browser Extension" ? "_blank" : ""}
                        key={i}
                        onClick={() => handleRouting(item.name)}
                    >
                        <div className="flex w-full cursor-pointer items-center gap-2 p-2 text-[#7A869B] hover:brightness-75">
                            <item.icon className="h-4 w-4" />
                            <p className="text-xs font-light">{item.name}</p>
                        </div>
                    </a>
                ))}

                <div className="my-1 flex w-full border-t-1 border-[#2F343F]" />
                <div className="flex w-full justify-center gap-3 pt-1">
                    {socials?.map((social) => (
                        <a
                            key={social.href}
                            className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-[#15181d] bg-none hover:bg-[#306CE8]"
                            href={social.href}
                            target="_blank"
                        >
                            {social.name === "X" ? (
                                <social.icon className="h-4 w-4" />
                            ) : (
                                <social.icon className="h-3 w-3 text-white" color="#FFFFFF" />
                            )}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};
