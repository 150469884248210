import React, { useCallback, useEffect, useRef, useState } from "react";
import MemoLoading from "@assets/SVG/Loading";
import { useStytch } from "@stytch/nextjs";
import cookie from "js-cookie";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function SupplyOTP({ email, setShowPasswordInput, methodId, setMethodId, setShowOTPInput }) {
    const inputRefs = useRef([] as any);
    const [countdown, setCountdown] = useState(60);
    const [OTP, setOTP] = useState("");
    const [OTPError, setOTPError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [OTPResponse, setOTPResponse] = useState("");
    const [values, setValues] = useState(["", "", "", "", "", ""]);
    const [shake, setShake] = useState(false);

    const stytchClient = useStytch();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    });

    useEffect(() => {
        if (OTPError) {
            setOTPError(false);
        }
        const emptyString = values?.some((value) => value === "");
        if (!emptyString) {
            const allValues = values.map((value) => Number(value));
            const OTP = allValues.join("");
            setOTP(OTP);
        } else {
            if (OTP) setOTP("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    useEffect(() => {
        if (!!OTP && !!email) {
            submitOTP();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTP]);

    const getOTP = () => {
        if (!email) return;
        setCountdown(60);
        stytchClient.otps.email.loginOrCreate(email).then((res) => {
            setMethodId(res?.method_id);
        });
    };

    const submitOTP = useCallback(() => {
        try {
            setLoading(true);
            stytchClient.otps
                .authenticate(OTP, methodId, {
                    session_duration_minutes: 10080,
                })
                .then((response) => {
                    setLoading(false);
                    cookie.set("session_token", response.session_token);
                    cookie.set("session_jwt", response.session_jwt);
                    setOTPResponse("Success");
                    setOTPError(false);
                    setShowOTPInput(false);
                    setShowPasswordInput(true);
                })
                .catch(() => {
                    setShake(true);
                    setLoading(false);
                    setOTPResponse("Error verifying OTP. Please try again");
                    setOTPError(true);
                    setTimeout(() => {
                        setShake(false);
                    }, 2000);
                });
        } catch (error) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stytchClient, OTP, methodId]);

    const handleChange = (index, event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9]*$/; // Regular expression to match only numbers

        if (!regex.test(inputValue)) {
            return;
        }

        const newValues = [...values];

        newValues[index] = inputValue;
        setValues(newValues);

        if (inputValue.length >= event.target.maxLength) {
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && values[index] === "") {
            const prevInput = inputRefs.current[index - 1];
            if (prevInput) {
                prevInput.focus();
            }
        }
    };

    const handlePaste = (event) => {
        event.preventDefault(); // Prevent the default paste behavior

        const pastedValue = event.clipboardData.getData("text/plain");
        const numbersOnly = [...pastedValue.replace(/\D/g, "")];

        let newArray;

        if (!numbersOnly) {
            return;
        } else {
            if (numbersOnly.length < 6) {
                const numToAdd = 6 - numbersOnly.length;
                newArray = numbersOnly.concat(Array(numToAdd).fill(""));
            } else {
                numbersOnly.splice(6);
                newArray = numbersOnly;
            }

            const updatedValues = newArray.map((newValue, index) => {
                const currentInput = inputRefs.current[index];
                if (currentInput) {
                    currentInput.value = newValue;
                }
                return newValue;
            });

            setValues(updatedValues);
        }
    };
    // 439isyo

    return (
        <div className="flex w-full flex-col items-start gap-3">
            <div className="mb-4 flex w-full items-center justify-start">
                <div
                    className="flex cursor-pointer gap-2 hover:brightness-75"
                    onClick={() => {
                        setShowOTPInput(false);
                        setShowPasswordInput(false);
                    }}
                >
                    <ArrowLeftIcon className="h-4 w-4" />
                    <p className="text-xs font-light text-[#7A869B]">Back to Email</p>
                </div>
            </div>
            <div className="mb-8 flex w-full flex-col gap-4 px-[6.5rem] text-xs font-light text-[#7A869B]">
                <p className="text-center">Thank you for registering!</p>
                <p className="text-center">
                    A One-Time Password (OTP) has been sent to{" "}
                    <span className="text-xs text-[#2E72FF]">{email}</span>.
                </p>
            </div>

            <div className="mb-4 flex w-full justify-center gap-4">
                {values.map((value, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={value}
                        className={`background-black ${
                            shake && "shake"
                        } input h-8 w-8 shrink-0 rounded-md border-1 ${
                            !OTP ? "border-[#2F343F]" : OTPError ? "border-[#FF4500]" : "border-[#D9CC5B]"
                        } px-0 text-center text-white`}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(event) => handleChange(index, event)}
                        onKeyDown={(event) => handleKeyDown(index, event)}
                        onPaste={(e) => handlePaste(e)}
                    />
                ))}
            </div>

            {(loading || !!OTPResponse) && (
                <div className="flex w-full justify-center text-center text-xs font-normal text-[#7A869B]">
                    {loading ? <MemoLoading className="w-4" /> : OTPResponse}
                </div>
            )}

            {countdown < 1 || countdown === 0 ? (
                <div
                    className="flex w-full flex-col items-center gap-1 text-center text-xs text-white"
                    onClick={getOTP}
                >
                    <div>Did not receive OTP?</div>
                    <div className="text-[#2E72FF]"> Resend OTP</div>
                </div>
            ) : (
                <div className="w-full text-center text-xs text-white">Resend in {countdown}s</div>
            )}
        </div>
    );
}
