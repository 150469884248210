import { SearchPayload } from "@/store/searchStore";
import { fetchPost } from "./apiBase";

const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
const SEARCH_API_URL =
    (process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
        (env === "production" ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`)) +
    "/search";

export enum LINKERS {
    OR = "OR",
    AND = "AND",
}

export type searchByTagsPayload = {
    query1: string;
    query2?: string;
    link?: LINKERS;
    from?: number;
    filter?: string;
    filterForLast7Days: boolean;
};

export const searchNewsByTags = async (neededFilterSet, searchPayload: SearchPayload) => {
    const res = await fetchPost(SEARCH_API_URL, { ...searchPayload, neededFilterSet });
    res.total = res.total?.value || 0;
    return res;
};

const newsIconFilters = [
    "Blog",
    "Government",
    "News",
    "*Release",
    "Reddit",
    "BlockBeat",
    "Podcast",
    "YouTube",
    "Twitter",
];

export const fetchNewsByIconFilter = async ({
    filter = newsIconFilters.join("%20OR%20"),
    from = 0,
    time = [],
    primarySourcesOnly = false,
}: {
    filter?: string;
    from?: number;
    time?: number[];
    primarySourcesOnly?: boolean;
}) => {
    const res = await fetchPost(`${SEARCH_API_URL}/search-news-by-icon`, {
        filter,
        from,
        time,
        primarySourcesOnly,
    });
    res.total = res.total?.value || 0;
    return res;
};

export const fetchBookmarkedNews = async ({
    filter = newsIconFilters.join("%20OR%20"),
    from = 0,
    time = [],
    primarySourcesOnly = false,
}: {
    filter?: string;
    from?: number;
    time?: number[];
    primarySourcesOnly?: boolean;
}) => {
    const res = await fetchPost(`${SEARCH_API_URL}/get-bookmarked-news`, {
        filter,
        from,
        time,
        primarySourcesOnly,
    });

    return res;
};

export const searchSingleTag = async ({ tag, from }: { tag: string; from?: number }) => {
    const res = await fetchPost(`${SEARCH_API_URL}/search-single-tag`, {
        tag,
        from,
    });

    return res;
};

export const searchTagsAcrossFields = async (searchPayload: searchByTagsPayload) => {
    const { query1, query2, link, from, filter, filterForLast7Days } = searchPayload;
    let searchFilters = filter;
    let linker = link;
    let searchFrom = from;

    if (!filter) {
        searchFilters = newsIconFilters.join("%20OR%20");
    }

    if (!link) {
        linker = LINKERS.AND;
    }

    if (!from || from === undefined) {
        searchFrom = 0;
    }

    let payload = `query1=${query1}&query2=${query2}&link=${linker}&from=${searchFrom}`;

    if (!query2) {
        payload = `query1=${query1}&from=${searchFrom}`;
    }

    const res = await fetchPost(`${SEARCH_API_URL}/search-tag-across-fields`, {
        searchFilters,
        payload,
        filterForLast7Days,
    });

    res.total = res.total?.value || 0;
    return res;
};

export const getUserByEmail = async ({ email }: { email: string }) => {
    const res = await fetchPost(`${SEARCH_API_URL}/search-user-by-email`, { email });
    return res;
};
