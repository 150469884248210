"use client";

import { useEffect } from "react";
import { useList } from "react-use";
import { useEventsContext } from "@app/dashboard/EventsContext";

const useNotifications = () => {
    const socket = useEventsContext();
    const [list, { push, reset }] = useList();

    useEffect(() => {
        if (socket.connected) {
            socket.on("notification", function (msg) {
                push(msg);
            });
        }
    }, [socket]);

    return [list, reset as any];
};

export default useNotifications;
