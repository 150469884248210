import { Dispatch, SetStateAction, useState } from "react";
import Modal from "@/components/Modal";
// import MemoQRCode from "@assets/SVG/QRCode";
import Link from "next/link";
import { ModalHeader } from "@app/sign-in/SignInConfirmationModal";
import MemoFullBlockbeatLogo from "@assets/SVG/Logo/FullBlockbeatLogo";
import SupplyOTP from "@app/auth/SupplyOTP";
import EmailInput from "@app/auth/EmailInput";
import PasswordInput from "@app/auth/PasswordInput";
import SocialLogins from "@app/auth/SocialLogins";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { isEmpty } from "lodash";

export const AuthenticationModal = ({
    show,
    setShow,
    setUserHasAPassword,
}: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    setUserHasAPassword: Dispatch<SetStateAction<boolean>>;
}) => {
    const [email, setEmail] = useState("");
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorType, setErrorType] = useState("");
    const [showOTPInput, setShowOTPInput] = useState(false);
    const [methodId, setMethodId] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);

    return (
        <Modal
            showCloseButton={false}
            className="overflow-hidden rounded-lg border-none px-0 pb-0 pt-0"
            show={show}
            setShow={setShow}
            dismissible={false}
        >
            <div className="flex flex-col items-center gap-[33px] bg-[#15191E] px-36 pb-[27px] pt-[38px]">
                <MemoFullBlockbeatLogo className="h-12 w-56" />
                <p className="text-lg font-normal leading-6 text-white">Welcome to BlockBeat Beta!</p>
                <div className="relative mb-20 mt-8 flex w-[400px] items-start rounded-lg bg-[#1C1F27] p-0">
                    <div className="flex min-h-[305px] w-full flex-col items-center justify-center gap-6 rounded-lg px-4 py-6">
                        {(success || !isEmpty(errorType)) && (
                            <div className="flex w-full flex-col items-center gap-1 px-4 pb-5 pt-4">
                                <ModalHeader success={success} errorType={errorType} />
                            </div>
                        )}
                        {!showOTPInput && (
                            <>
                                {showPasswordInput && (
                                    <div className="flex w-full items-center justify-start">
                                        <div
                                            className="flex cursor-pointer gap-2 hover:brightness-75"
                                            onClick={() => setShowPasswordInput(false)}
                                        >
                                            <ArrowLeftIcon className="h-4 w-4" />
                                            <p className="text-[14px] font-light leading-4 text-[#7A869B]">
                                                Back to Email
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <p className="text-[14px] font-light leading-4 text-[#7A869B]">
                                    {!showPasswordInput
                                        ? "Login or Sign Up"
                                        : showConfirmPassword
                                        ? "Please Create a Password"
                                        : "Please Enter Your Password"}
                                </p>
                            </>
                        )}

                        {showOTPInput ? (
                            <SupplyOTP
                                email={email}
                                methodId={methodId}
                                setMethodId={setMethodId}
                                setShowPasswordInput={setShowPasswordInput}
                                setShowOTPInput={setShowOTPInput}
                            />
                        ) : (
                            <div className="flex w-full flex-col items-start gap-3">
                                {!showPasswordInput ? (
                                    <EmailInput
                                        email={email}
                                        setEmail={setEmail}
                                        setShowPasswordInput={setShowPasswordInput}
                                        setMethodId={setMethodId}
                                        setShowOTPInput={setShowOTPInput}
                                        setSuccess={setSuccess}
                                        setErrorType={setErrorType}
                                        setShowConfirmPassword={setShowConfirmPassword}
                                    />
                                ) : (
                                    <PasswordInput
                                        email={email}
                                        setErrorType={setErrorType}
                                        setSuccess={setSuccess}
                                        setUserHasAPassword={setUserHasAPassword}
                                        showConfirmPassword={showConfirmPassword}
                                    />
                                )}

                                {/* socials */}
                                {!showPasswordInput && <SocialLogins />}

                                <div className="flex flex-col items-center gap-1 p-0">
                                    <Link
                                        href={`/reset-password?email=${email}`}
                                        className="cursor-pointer hover:brightness-75"
                                        passHref
                                    >
                                        <div className="flex items-start gap-2 p-0">
                                            <p className="text-center text-xs text-[#2E72FF]">
                                                Forgot password?
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <p className="mt-4 text-center text-[10px] font-light leading-3 text-[#A1ADC1]">
                    By proceeding you agree to <span className="text-[#9B9DA6]">the</span>
                    <a
                        href="https://www.blockbeat.io/terms-and-conditions"
                        target="_blank"
                        className="text-[#306CE8]"
                    >
                        {" "}
                        Terms and Conditions
                    </a>
                    <span className="text-[#9B9DA6]">.</span>
                </p>
            </div>
        </Modal>
    );
};
