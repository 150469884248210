"use client";

import Link from "next/link";
import MemoFullBlockbeatLogo from "@assets/SVG/Logo/FullBlockbeatLogo";
import userPaymentState from "@/store/userPaymentStore";
import { useSnapshot } from "valtio";

export default function BlockBeatBetaLogo() {
    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive: userIsPro } = userSubscriptionStatus;
    return (
        <Link href="/dashboard" className="relative">
            {userIsPro && (
                <div className="absolute right-[10px] top-[5px] w-[fit-content] rounded-2xl border-[1.5px] border-solid border-[#20242C] bg-[#C83D4D] px-1 text-[8px] uppercase leading-[10px] text-white">
                    BETA
                </div>
            )}
            <MemoFullBlockbeatLogo className="mr-8 h-12 w-32 cursor-pointer hover:brightness-75" />
        </Link>
    );
}
