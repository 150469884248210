import { globalOverlayProxy } from "@/store/globalStore";
import { BellIcon } from "@heroicons/react/24/outline";

export default function NotificationButton({ notifications, setShow }) {
    return (
        <div
            onClick={() => {
                globalOverlayProxy.setZIndex = true;
                setShow(true);
            }}
            className={`${
                notifications?.length > 0 && "animate-[tilt-shaking_0.25s_infinite]"
            } relative cursor-pointer hover:brightness-75`}
        >
            <BellIcon className="h-[17px] w-[17px] text-[#7A869B]" />
            {notifications.length > 0 && (
                <div className="absolute right-[1px] top-[-2px] h-2 w-2 rounded-full border-[1.5px] border-solid border-[#20242C] bg-[#306CE8]" />
            )}
        </div>
    );
}
