"use client";

import NotificationButton from "@app/NotificationButton";
import { ProfileButton } from "@app/ProfileButton";
import { NotificationModal } from "@app/NotificationModal";
import { useEffect, useState } from "react";
import useNotifications from "@app/useNotifications";
import { UserDropdownModal } from "@app/profile/UserDropdownModal";
import { AuthenticationModal } from "@app/auth/AuthenticationModal";
// import { TIER } from "@/types/api";
import { InformationCircleIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useStytchSession, useStytchUser } from "@stytch/nextjs";
import MemoLoading from "@assets/SVG/Loading";
// import { WelcomeModal } from "@app/dashboard/WelcomeModal";
import useLocalStorageState from "use-local-storage-state";
import { useSnapshot } from "valtio";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";
// import { getReferredUsersList, getVerifiedAccessListContacts } from "@/services/email";
import { globalOverlayProxy, globalProxy } from "@/store/globalStore";
import Cookies from "js-cookie";
import { useUserSubscription } from "@app/useUserSubscription";
import userPaymentState, { paymentState } from "@/store/userPaymentStore";
import { UpgradeModal } from "@app/dashboard/news-feed/news-list/UpgradeModal";
// import NewsItemModal from "@app/dashboard/[articleId]/news-detail/NewsItemModal";

export function SessionControls() {
    const [showSignIn, setShowSignIn] = useState(false);
    const [notifications, reset] = useNotifications();
    const [showNotifications, setShowNotifications] = useState(false);
    const [showUserDropdownModal, setShowUserDropdownModal] = useState(false);
    // const [showAccessModal, setShowAccessModal] = useState(false);
    const { loading: userPaymentIsLoading, subIsActive } = useSnapshot(userPaymentState);
    const { changed } = useSnapshot(paymentState);

    const { session, isInitialized } = useStytchSession();
    const modalGlobalProxy = useSnapshot(globalProxy);
    const isNewsModalOpen = modalGlobalProxy.isNewsModalOpen;
    const [showUpgrade, setShowUpgrade] = useState(false);

    const jwt = Cookies.get("stytch_session_jwt") as string;
    useUserSubscription(session!, jwt!, changed);

    const userIsPro = subIsActive;

    const { user } = useStytchUser();

    const userIsVerified = session && !!user?.password?.password_id && user?.emails?.[0]?.verified;
    const sessionIsOauth = session && session?.authentication_factors[0].type === "oauth";
    const sessionIsMgicLink = session && session?.authentication_factors[0].type === "magic_link";

    const [userHasAPassword, setUserHasAPassword] = useState(userIsVerified as boolean);
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);

    const [firstVisit, setFirstVisit] = useLocalStorageState("firstVisit", {
        defaultValue: 0,
    });

    const LOCKOUT_DURATION_MS = 15000; // 15 seconds

    useEffect(() => {
        if (!firstVisit) {
            setFirstVisit(Date.now());
        }
    }, [firstVisit, setFirstVisit]);

    useEffect(() => {
        if (!session) {
            setUserHasAPassword(false);
            if (firstVisit) {
                const elapsedTime = Date.now() - firstVisit;
                if (elapsedTime > LOCKOUT_DURATION_MS) {
                    globalOverlayProxy.setZIndex = true;
                    setShowSignIn(true);
                }
            } else {
                globalOverlayProxy.setZIndex = true;
                setTimeout(() => setShowSignIn(true), 3000);
            }
        } else {
            globalOverlayProxy.setZIndex = false;
        }
    }, [session, firstVisit]);

    // useEffect(() => {
    //     const jwt = Cookies.get("stytch_session_jwt") as string;

    //     const user_email = user?.emails?.[0]?.email;
    //     if (user_email) {
    //         getVerifiedAccessListContacts(jwt).then((res) => {
    //             const verifiedList = res;
    //             const userIsVerified: any = verifiedList?.some((user: any) => user?.email === user_email);
    //             if (userIsVerified) {
    //                 setShowAccessModal(false);
    //             } else {
    //                 getReferredUsersList(jwt).then((res) => {
    //                     const referredUsers = res;
    //                     const userIsOnReferredList: any = referredUsers?.some(
    //                         (user: any) => user === user_email,
    //                     );
    //                     if (userIsOnReferredList) {
    //                         setShowAccessModal(false);
    //                     } else {
    //                         setShowAccessModal(true);
    //                     }
    //                 });
    //             }
    //         });
    //     }
    // }, [user, session]);

    return (
        <>
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            {!isInitialized ? (
                <MemoLoading className="w-4" />
            ) : userIsVerified || sessionIsOauth || sessionIsMgicLink || userHasAPassword ? (
                <div className="relative flex items-center gap-4 px-2">
                    {showNotifications && (
                        <NotificationModal
                            notifications={notifications}
                            clearNotifications={() => {
                                setShowNotifications(false);
                                reset();
                            }}
                            show={showNotifications}
                            setShow={setShowNotifications}
                        />
                    )}
                    <NotificationButton notifications={notifications} setShow={setShowNotifications} />
                    {showUserDropdownModal && (
                        <UserDropdownModal show={showUserDropdownModal} setShow={setShowUserDropdownModal} />
                    )}
                    <ProfileButton setShowUserDropdownModal={setShowUserDropdownModal} />
                    {/* {showAccessModal && <WelcomeModal show={showAccessModal} setShow={setShowAccessModal} />} */}
                    {userPaymentIsLoading ? (
                        <MemoLoading className="w-4" />
                    ) : (
                        !userIsPro && (
                            <UpgradeModal
                                show={showUpgrade}
                                setShow={setShowUpgrade}
                                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                            >
                                <div
                                    onClick={() => {
                                        globalOverlayProxy.setZIndex = true;
                                        setShowUpgrade(true);
                                    }}
                                    className="cursor-pointer rounded-3xl border-none bg-[#306CE8] px-2 py-1 hover:brightness-75"
                                >
                                    <p className="whitespace-normal text-xs font-normal text-white">
                                        Upgrade Now
                                    </p>
                                </div>
                            </UpgradeModal>
                        )
                    )}

                    <div className="hidden h-0 w-4 rotate-90 border-[1px] border-solid border-[#373C46] lg:flex" />
                    {userPaymentIsLoading ? (
                        <MemoLoading className="w-4" />
                    ) : userIsPro ? (
                        <div
                            className="group relative hidden lg:flex"
                            onPointerEnter={() => {
                                globalOverlayProxy.setZIndex = true;
                                setShowUserDropdownModal(false);
                            }}
                            onPointerLeave={() => (globalOverlayProxy.setZIndex = false)}
                        >
                            <div className="relative flex items-center gap-[8px] px-1">
                                <div className="h-2 w-2 animate-pulse rounded-full bg-[#27AF8F]" />
                                <p className="text-xs font-light text-[#7A8597]">Live Data</p>
                            </div>
                            <div className="invisible absolute right-2 top-[26px] z-[60] flex w-60 flex-col items-start rounded-lg border-b-1 border-[#2F343F] bg-[#252932] px-0 py-1 group-hover:visible group-hover:brightness-100">
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <CheckCircleIcon className="h-6 w-6 text-[#27AF8F]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">News Feed</p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Real Time</p>
                                </div>
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <CheckCircleIcon className="h-6 w-6 text-[#27AF8F]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">Pricing Data</p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Real Time</p>
                                </div>
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <CheckCircleIcon className="h-6 w-6 text-[#27AF8F]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">Search</p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Unlocked</p>
                                </div>
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <CheckCircleIcon className="h-6 w-6 text-[#27AF8F]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">
                                            Custom Filter Sets
                                        </p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Unlocked</p>
                                </div>
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <XCircleIcon className="h-6 w-6 text-[#C83D4D]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">Custom Watchlists</p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Locked</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="group relative hidden lg:flex"
                            onPointerEnter={() => {
                                globalOverlayProxy.setZIndex = true;
                                setShowUserDropdownModal(false);
                            }}
                            onPointerLeave={() => (globalOverlayProxy.setZIndex = false)}
                        >
                            <div className="relative flex cursor-pointer items-center gap-[8px] px-1 hover:brightness-75">
                                <div className="h-2 w-2 animate-pulse rounded-full bg-[#FF6B00]" />
                                <p className="text-xs font-light text-[#A1ADC1]">Limited Access</p>
                                <InformationCircleIcon className="h-3 w-3 text-[#7A8597]" />
                            </div>
                            <div className="invisible absolute right-2 top-[42px] z-[60] flex w-60 flex-col items-start rounded-lg border-b-1 border-[#2F343F] bg-[#252932] px-0 py-1 group-hover:visible group-hover:brightness-100">
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <CheckCircleIcon className="h-6 w-6 text-[#27AF8F]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">News Feed</p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Real Time</p>
                                </div>
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <CheckCircleIcon className="h-6 w-6 text-[#27AF8F]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">Pricing Data</p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Real Time</p>
                                </div>
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <XCircleIcon className="h-6 w-6 text-[#C83D4D]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">Search</p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Locked</p>
                                </div>
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <XCircleIcon className="h-6 w-6 text-[#C83D4D]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">
                                            Custom Filter Sets
                                        </p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Locked</p>
                                </div>
                                <div className="flex w-full items-center justify-between gap-2 p-2">
                                    <div className="flex items-center gap-1 p-0">
                                        <XCircleIcon className="h-6 w-6 text-[#C83D4D]" />
                                        <p className="text-xs font-light text-[#A1ADC1]">Custom Watchlists</p>
                                    </div>
                                    <p className="text-xs font-normal text-white">Locked</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {showSignIn && !isNewsModalOpen && (
                        <AuthenticationModal
                            show={showSignIn}
                            setShow={setShowSignIn}
                            setUserHasAPassword={setUserHasAPassword}
                        />
                    )}

                    {/* <NewsItemModal /> */}

                    <LoginSignupLink setShow={setShowSignIn} />
                </>
            )}
        </>
    );
}

const LoginSignupLink = ({ setShow }) => (
    <div
        onClick={() => setShow(true)}
        className="flex cursor-pointer items-center gap-2 px-1 py-4 hover:brightness-75"
    >
        <div className="flex h-5 w-5 items-center justify-center">
            <UserCircleIcon className="h-[17px] w-[17px] text-[#306CE8]" />
        </div>
        <p className="w-auto text-xs font-normal text-[#2E72FF]">Login / Sign Up</p>
    </div>
);
