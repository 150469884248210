import { globalOverlayProxy } from "@/store/globalStore";
import { UserCircleIcon } from "@heroicons/react/24/outline";

export function ProfileButton({ setShowUserDropdownModal }) {
    return (
        <div
            onPointerEnter={() => {
                globalOverlayProxy.setZIndex = true;
                setShowUserDropdownModal(true);
            }}
            className="relative cursor-pointer hover:brightness-75"
        >
            <UserCircleIcon className="h-[17px] w-[17px] text-[#7A869B]" />
        </div>
    );
}
