import Cookies from "js-cookie";

export const fetchPost = async (url: string, payload: any = {}) => {
    const jwt = Cookies.get("stytch_session_jwt") as string;
    const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            jwt,
        },
    });
    return res.json();
};
