import Modal from "@components/Modal";
import MemoFlame from "@assets/SVG/Flame";
import { isEmpty } from "lodash";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";

const NotificationItem = ({ notification }) => {
    return (
        <div className="flex flex-col items-start gap-2 px-4 py-2">
            <div className="flex w-full items-start gap-2 p-0">
                <div className="flex h-5 w-5 items-center justify-center rounded-[4px] bg-[#FF6B00]">
                    <MemoFlame className="h-[10px] w-[9px]" color="#FFFFFF" />
                </div>
                <div className="flex w-full flex-col items-start justify-center gap-1 p-0">
                    <div className="flex w-full items-center justify-between px-0 py-[2px]">
                        <p className="text-xs font-light text-white">{notification.type}</p>
                        <p className="text-[10px] font-light leading-3 text-[#7A869B]">2:56pm</p>
                    </div>
                    <p className="text-xs font-normal text-white">
                        {notification.headline}
                        <span className="ml-1 text-[10px] font-light leading-3 text-[#505A68]">
                            {notification.source}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export const NotificationModal = ({
    notifications,
    clearNotifications,
    show,
    setShow,
}: {
    notifications: any[];
    clearNotifications: () => void;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) => {
    return (
        <Modal show={show} setShow={setShow} right className="max-h-screen w-[360px]">
            <div className="shadow-[-8px 0px 15px rgba(0, 0, 0, 0.2)] relative flex w-full flex-col border-l-1 border-[#2F343F] bg-[#20242C]">
                <div className="flex w-full flex-col items-start border-b-1 border-[#2F343F] px-4 py-3">
                    <div className="flex items-center justify-between gap-[149px] p-0">
                        <p className="text-base font-normal leading-5 text-white">Notifications</p>
                    </div>
                </div>

                {isEmpty(notifications) ? (
                    <div className="mt-8 flex flex-col items-center justify-center gap-2 pb-16 text-[#505A68]">
                        <ExclamationCircleIcon className="h-8 w-8" />
                        <p className="text-[#505A68]">No match</p>
                    </div>
                ) : (
                    <div className="flex h-full w-full flex-col items-start overflow-y-scroll p-0 pb-24">
                        <div className="flex w-full flex-col">
                            <div className="flex w-full items-start gap-2 border-b-1 border-[#2F343F] px-4 py-2">
                                <p className="text-xs text-[#7A869B]">Today</p>
                            </div>
                            <div className="flex w-full flex-col divide-y-1 divide-[#2F343F]">
                                {notifications.map((notification, i) => (
                                    <NotificationItem key={i} notification={notification} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                <div className="fixed bottom-0 flex w-full flex-col">
                    <div className="news-gradient flex h-14 w-full" />

                    <div
                        onClick={clearNotifications}
                        className="flex w-full cursor-pointer flex-col items-center gap-3 border-1 border-[#2F343F] bg-[#20242C] px-4 py-3 hover:brightness-75"
                    >
                        <div className="flex items-center justify-between gap-4 p-0">
                            <div className="flex items-center gap-[2px] p-0">
                                <div className="flex h-4 w-4 items-center justify-center">
                                    <div className="h-3 w-3 rounded-full bg-[#7A869B]" />
                                </div>
                                <p className="text-xs text-[#A1ADC1]">Clear All</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
