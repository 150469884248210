import { SVGProps, memo } from "react";

function Logout(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
            <path
                d="M8.5 5V2.5A1.5 1.5 0 007 1H3a1.5 1.5 0 00-1.5 1.5v9A1.5 1.5 0 003 13h4a1.5 1.5 0 001.5-1.5V9m2 0l2-2m0 0l-2-2m2 2H4"
                stroke={props.color || "#7A869B"}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoLogout = memo(Logout);
export default MemoLogout;
