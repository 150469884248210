"use client";

import { ClockIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";

const CountdownClock = () => {
    const [hour, setHour] = useState(23 - new Date().getUTCHours());
    const [minute, setMinute] = useState(60 - new Date().getUTCMinutes());
    const [second, setSecond] = useState(60 - new Date().getUTCSeconds());

    useEffect(() => {
        const intervalId = setInterval(() => {
            const UTCHour = new Date().getUTCHours();
            const UTCMinute = new Date().getUTCMinutes();
            const UTCSecond = new Date().getUTCSeconds();

            const countdownHour = 23 - UTCHour;
            const countdownMinute = 60 - UTCMinute;
            const countdownSecond = 60 - UTCSecond;

            if (countdownHour < 10) {
                setHour(`0${countdownHour}` as any);
            } else setHour(countdownHour);

            if (countdownMinute < 10) {
                setMinute(`0${countdownMinute}` as any);
            } else setMinute(countdownMinute);

            if (countdownSecond < 10) {
                setSecond(`0${countdownSecond}` as any);
            } else setSecond(countdownSecond);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    });

    return (
        <div className="hidden items-center px-1 lg:flex">
            <ClockIcon className="mr-3 h-[15px] w-[15px] text-[#A1ADC1]" />
            <div className="flex text-xs font-light text-[#A1ADC1]">
                <p className="mr-[3px]">Daily Close:</p>
                <span className="flex w-4 items-center justify-center"> {hour}</span>:
                <span className="flex w-4 items-center justify-center">{minute}</span>:
                <span className="flex w-4 items-center">{second}</span>
            </div>
        </div>
    );
};

export default CountdownClock;
