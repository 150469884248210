import { retrieveMostRecentPayment } from "@/services/payment";
import userPaymentState from "@/store/userPaymentStore";
import { Session } from "@stytch/vanilla-js";
import { useEffect, useState } from "react";

export const useUserSubscription = (session: Session | null, jwt: string, changed: boolean) => {
    const [paymentData, setPaymentData] = useState(null as any);
    const [error, setError] = useState(null);
    const [userPaymentIsLoading, setLoading] = useState(true);
    const [subIsActive, setSubIsActive] = useState(false);

    useEffect(() => {
        const userHasActiveSubscription = async () => {
            try {
                const data: { message: string; payment: any; userHasActiveSubscription: boolean } =
                    await retrieveMostRecentPayment(jwt);

                const { userHasActiveSubscription, payment } = data;
                setPaymentData(payment);
                setSubIsActive(userHasActiveSubscription);
            } catch (error) {
                setError(error as any);
                setSubIsActive(false);
            } finally {
                setLoading(false);
            }
        };
        if (session) {
            userHasActiveSubscription();
        } else setSubIsActive(false);
    }, [session, changed]);

    userPaymentState.error = error;
    userPaymentState.loading = userPaymentIsLoading;
    userPaymentState.paymentData = paymentData;
    // userPaymentState.subIsActive = subIsActive; //TODO: remove this for now so all features are open to users.

    return { paymentData, subIsActive, userPaymentIsLoading, error };
};
